<template>
<div class="order-contract">
  <!-- <div>
    <iframe :src="contractViewUrl" class="contract"></iframe>
  </div> -->
  <div class="common-footer">
    <van-button class="btn back" @click="goBack">{{$t('返回')}}</van-button>
  </div>
</div>
</template>
<script>
import orderServices from '@/services/orderServices' 
export default {
  name:'orderContract',
  data(){
    return {
      contractViewUrl: 'http://bu5-dev-1256488924.cos.ap-chengdu.myqcloud.com/contract-pdf/8d7d32de826c4242a296aa2881ed5b53.pdf',
    }
  },
  mounted(){
    const { contractViewUrl } = this.$route.query
    if (contractViewUrl) this.contractViewUrl = contractViewUrl
    this.viewHandler()
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    viewHandler(){
      wx.previewFile({
        url: this.contractViewUrl, // 需要预览文件的地址(必填，可以使用相对路径)
        name: '8d7d32de826c4242a296aa2881ed5b53.pdf', // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
        size: 222301 // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.order-contract{
  .contract{
    width: 100%;
    border: none;
    height: calc(100vh - 96px);
  }
}
</style>